import React, { useCallback, useMemo } from "react";

import { forwardRef, useImperativeHandle, useRef } from "react";
import {
    extendVideoProps,
    VideoPlayerHandle,
    VideoPlayerProps,
} from "./video-player";

import styles from "@app/assets/styles/video-player.module.scss";

export const BasicVideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
    (props, ref) => {
        const { video } = props;
        const videoRef = useRef<HTMLVideoElement>(null);

        useImperativeHandle(ref, () => ({
            play: () => {
                videoRef?.current?.play();
            },

            pause: () => {
                videoRef?.current?.pause();
            },

            seek: (timecode: number) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.currentTime = timecode;
            },
            selectAudioTrack: (id: number | undefined) => {
                console.log("select audio track", id);
            },
            selectSubtitleTrack: (id: number | undefined) => {
                console.log("select subtitle track", id);
            },
            muted: (muted: boolean) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.muted = muted;
            },
            volume: (volume: number) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.volume = volume;
            },
        }));

        const handleVideoLoaded = useCallback(() => {
            props?.onLoaded?.({
                duration: videoRef?.current?.duration ?? 0,
            });
        }, []);

        const eventVideoProps = useMemo(() => {
            return extendVideoProps(props);
        }, []);

        return (
            <div className={styles.playableVideoContainer}>
            <video
                ref={videoRef}
                src={video.url}
                onLoadedMetadata={() => handleVideoLoaded()}
                onWaiting={() => { props?.onBuffering?.(true); }}
                onCanPlayThrough={() => { props?.onBuffering?.(false); }}
                playsInline={props.playInline}
                {...eventVideoProps}
            ></video>
            </div>
        );
    }
);
