import {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";

import { stringify as queryStringify } from "qs";

import { customTextDisplayerFactory } from "@app/components/shaka/text-displayer";

import styles from "@app/assets/styles/video-player.module.scss";

import {
    AudioTrack,
    extendVideoProps,
    SubtitleTrack,
    VideoPlayerHandle,
    VideoPlayerProps,
} from "./video-player";
import { buildWidevineNetFilter } from "../shaka/drm";

declare const shaka: any;

function getFixedCurrentTime(videoNode: HTMLVideoElement | null) {
    const currentTime = Math.round(videoNode?.currentTime ?? 0);

    // Come back one second before or if current time is 0 starts at 1
    const newCurrentTime = (currentTime == 0) ? 1 :
        Math.max(0, currentTime - 1);

    return newCurrentTime;
}

export const ShakaVideoPlayer = forwardRef<VideoPlayerHandle, VideoPlayerProps>(
    (props, ref) => {
        const { video, autoPlay, onError, onBuffering } = props;
        const containerRef = useRef<HTMLDivElement>(null);
        const videoRef = useRef<HTMLVideoElement | null>(null);
        const bufferingTimeout = useRef<NodeJS.Timeout | null>(null);
        const shakaRef = useRef(null);
        const [isVideoNodeReady, setVideoNodeReady] = useState<boolean>(false);

        const audioTracksRef = useRef<string | undefined>();
        const subtitleTracksRef = useRef<string | undefined>();

        useImperativeHandle(ref, () => ({
            play: () => {
                const playPromise = videoRef?.current?.play();

                if (playPromise != null) {
                    playPromise.then(() => {
                        // Play started successfully
                    }).catch((error) => {
                        // Play error
                        console.log("Play error", error);
                    });
                }
            },

            pause: () => {
                videoRef?.current?.pause();
            },

            seek: (timecode: number) => {
                if (videoRef.current) {
                    videoRef.current.currentTime = Math.round(timecode);
                }
            },
            selectAudioTrack: (id: number | undefined) => {
                const shakaPlayer: any = shakaRef.current;

                if (shakaPlayer == null) {
                    return;
                }

                if (id != null) {
                    const shakaAudioLangs = shakaPlayer.getAudioLanguages();
                    shakaPlayer.selectAudioLanguage(shakaAudioLangs[id]);
                }

                if (props.onAudioTrackChange) {
                    props?.onAudioTrackChange(id);
                }
            },
            selectSubtitleTrack: (id: number | undefined) => {
                const shakaPlayer: any = shakaRef.current;

                if (shakaPlayer == null) {
                    return;
                }

                if (id != null) {
                    if (id > 0) {
                        const textLanguage =
                            shakaPlayer.getTextLanguages()?.[id - 1];
                        shakaPlayer.selectTextLanguage(textLanguage);
                        shakaPlayer.setTextTrackVisibility(true);
                    } else {
                        shakaPlayer.setTextTrackVisibility(false);
                    }
                }

                if (props.onSubtitleTrackChange) {
                    props?.onSubtitleTrackChange(id);
                }
            },
            muted: (muted: boolean) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.muted = muted;
            },
            volume: (volume: number) => {
                if (videoRef.current == null) {
                    return;
                }

                videoRef.current.volume = volume;
            },
        }));

        // Player state
        const handlePlayerBuffering = useCallback(() => {
            const shakaPlayer = shakaRef.current as any;

            if (shakaPlayer == null || onBuffering == null) {
                return;
            }

            const isBuffering = shakaPlayer.isBuffering();

            if (isBuffering) {
                bufferingTimeout.current = setTimeout(() => {
                    // Shaka player is still buffering
                    if (shakaPlayer.isBuffering()) {
                        console.log("Fix buffering event");
                        // Fix Safari that do not detect end of buffering
                        const newCurrentTime = getFixedCurrentTime(videoRef?.current);

                        if (videoRef.current != null) {
                            videoRef.current.currentTime = newCurrentTime;
                        }
                    }
                }, 4000);
            } else {
                if (bufferingTimeout.current != null) {
                    clearTimeout(bufferingTimeout.current);
                }
            }

            onBuffering(isBuffering);
        }, [onBuffering])

        // Error handler
        const handlePlayerError = useCallback((error: unknown) => {
            console.log("ERROR IN SHAKA ", error);
            if (onError != null) {
                onError(error);
            }
        }, []);

        const initTracks = useCallback(() => {
            const shakaPlayer = shakaRef.current as any;

            if (shakaPlayer == null) {
                return;
            }

            // Get active variant to get audio language
            const activeVariant = shakaPlayer
                .getVariantTracks()
                .find((variant: any) => variant.active);

            // Set state audios
            let defaultAudioId: number | undefined = undefined;
            const audioLangs = shakaPlayer.getAudioLanguages();

            // let defaultSubtitleId: number | undefined = undefined;

            // Check if text tracks are defined in manifest
            const textLangs = shakaPlayer.getTextLanguages();

            if ((JSON.stringify(audioLangs) !== audioTracksRef.current) || (JSON.stringify(textLangs) !== subtitleTracksRef.current)) {
                audioTracksRef.current = JSON.stringify(audioLangs);
                subtitleTracksRef.current = JSON.stringify(textLangs);
                const audioTracks: AudioTrack[] = [];

                audioLangs.forEach((langCode: string, index: number) => {
                    if (activeVariant?.language == langCode) {
                        defaultAudioId = index;
                    }

                    audioTracks.push({
                        id: index,
                        langCode,
                    });

                });

                const subtitleTracks: SubtitleTrack[] = [];

                if (textLangs.length > 0) {
                    subtitleTracks.push({ id: 0, langCode: "none" });
                    textLangs.forEach((language: string, index: number) => {
                        subtitleTracks.push({
                            id: index + 1,
                            langCode: language,
                        });
                    });
                }

                props?.onLoaded?.({
                    duration: videoRef.current
                        ? videoRef?.current?.duration
                        : 0,
                    subtitleTracks,
                    selectedSubtitleTrackId: 0,
                    audioTracks,
                    selectedAudioTrackId: defaultAudioId,
                });
            }
        }, []);

        const initShaka = useCallback(async () => {
            try {
                // Install built-in polyfills to patch browser incompatibilities.
                shaka.polyfill.installAll();
                shaka.polyfill.PatchedMediaKeysApple.install();
                const FairPlayUtils = shaka.util.FairPlayUtils;

                // Check to see if the browser supports the basic APIs Shaka needs.
                if (!shaka.Player.isBrowserSupported()) {
                    throw new Error(
                        "Your browser is not compatible with the player."
                    );
                }
                const shakaPlayer = new shaka.Player(videoRef.current);
                (window as any).shakaPlayer = shakaPlayer;
                shakaRef.current = shakaPlayer;
                shakaPlayer.addEventListener("error", handlePlayerError);
                shakaPlayer.addEventListener("trackschanged", initTracks);
                shakaPlayer.addEventListener("variantchanged", initTracks);
                shakaPlayer.addEventListener("buffering", handlePlayerBuffering);

                // Configure player
                const playerConfig: any = {};
                const requestNetFilters: any[] = [];
                const responseNetFilters: any[] = [];

                if (video?.drmConfig != null) {
                    if (video.drmConfig.type == "widevine") {
                        playerConfig.drm = {
                            servers: {
                                "com.widevine.alpha": video.drmConfig.serverUrl,
                            },
                        };
                        const drmRequestNetFilter =
                            await buildWidevineNetFilter(shakaPlayer, video);

                        if (drmRequestNetFilter != null) {
                            requestNetFilters.push(drmRequestNetFilter);
                        }
                    } else if (video.drmConfig.type == "fairplay") {
                        playerConfig.drm = {
                            servers: {
                                "com.apple.fps.1_0":
                                    video.drmConfig.serverUrl + "?"
                                        + queryStringify(video.drmExtra ?? {}),
                            },
                            advanced: {
                                "com.apple.fps.1_0": {
                                    serverCertificateUri:
                                        video.drmConfig.fairplayCertificateUrl,
                                },
                            },
                            initDataTransform:
                                FairPlayUtils.ezdrmInitDataTransform,
                        };
                        requestNetFilters.push(
                            FairPlayUtils.ezdrmFairPlayRequest
                        );
                        responseNetFilters.push(
                            FairPlayUtils.commonFairPlayResponse
                        );
                    }
                }

                // Override min buffer time if defined
                playerConfig.manifest = {
                    dash: {
                        ignoreMinBufferTime: true,
                    },
                };
                playerConfig.streaming = {
                    failureCallback: (error: any) => {
                        if (error.severity === 2 && [3014, 3015, 3016].includes(error.code)) {
                            console.log("Streaming error", error);
                            // This is a fix for Firefox browser
                            // that sometimes crash when it tries to decode video

                            // Retry streaming
                            shakaPlayer.unload();

                            const newCurrentTime = getFixedCurrentTime(videoRef?.current)
                            shakaPlayer.load(video.url, newCurrentTime);
                        }
                    },
                    alwaysStreamText: true,
                    rebufferingGoal: 2,
                    segmentPrefetchLimit: 2,
                    bufferingGoal: 10,
                    ignoreTextStreamFailures: true,
                    retryParameters: {
                        maxAttempts: 3,
                    },
                };

                // Configure subtitle
                playerConfig.textDisplayFactory = customTextDisplayerFactory(
                    videoRef.current,
                    containerRef.current
                );

                // Everything looks good!
                shakaPlayer.configure(playerConfig);

                // Register network filters
                const nwe = shakaPlayer.getNetworkingEngine();
                requestNetFilters.forEach((netFilter: any) => {
                    nwe.registerRequestFilter(netFilter);
                });
                responseNetFilters.forEach((netFilter: any) => {
                    nwe.registerResponseFilter(netFilter);
                });

                // Load video manifest
                await shakaPlayer.load(video.url);
            } catch (error) {
                handlePlayerError(error);
            }

            return () => {
                const shakaPlayer = shakaRef.current as any;

                if (shakaPlayer != null) {
                    shakaPlayer?.removeEventListener("error", handlePlayerError);
                    shakaPlayer?.removeEventListener("trackschanged", initTracks);
                    shakaPlayer?.removeEventListener("variantchanged", initTracks);
                    shakaPlayer?.removeEventListener("buffering", handlePlayerBuffering);
                    shakaPlayer.unload();
                }
            }
        }, []);

        // Used to initialize player
        const initVideoRef = useCallback((node: any) => {
            if (node == null) {
                return;
            }

            videoRef.current = node;
            setVideoNodeReady(true);
        }, []);

        // Mount/unmount player
        useEffect(() => {
            initShaka().then(() => {
                console.log("video launched");
            });

            return () => {
                const removePlayer = async () => {
                    if (shakaRef.current) {
                        await (shakaRef.current as any).detach();
                    }
                };

                removePlayer().catch((error) => {
                    console.log(error);
                });
            };
        }, [isVideoNodeReady]);

        const eventVideoProps = useMemo(() => {
            return extendVideoProps(props);
        }, [props.onPlay, props.onPause, props.onEnded, props.onTimeUpdate]);

        return (
            <div ref={containerRef} className={styles.playableVideoContainer}>
                <video
                    {...eventVideoProps}
                    autoPlay={!!autoPlay}
                    ref={initVideoRef}
                    playsInline={props.playInline}
                ></video>
            </div>
        );
    }
);
